import _Checkbox from "./Checkbox";
import _Input from "./Input";
import _Radio from "./Radio";
import _TextArea from "./TextArea";
import _Range from "./Slider";
import _Password from "./Password";

const Form = {
  Input: _Input,
  TextArea: _TextArea,
  Checkbox: _Checkbox,
  Radio: _Radio,
  Range: _Range,
  Password: _Password,
};

export default Form;
