import classNames from "classnames";
import { useTranslation } from "next-i18next";

export type LabelTextStyle = "gray" | "light-gray";

export type LabelProps = {
  id?: string;
  label?: string;
  textStyle?: LabelTextStyle;
  showOptional?: boolean;
};

const Label = ({
  id,
  label,
  textStyle = "gray",
  showOptional = false,
}: LabelProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <label
      className={classNames(
        "inline-block text-gray-500 caption-1 uppercase tracking-wider",
        {
          "text-gray-500": textStyle === "gray",
          "text-gray-50": textStyle === "light-gray",
        }
      )}
      htmlFor={id}
    >
      <p className="flex items-center justify-center gap-2">
        <span>{label}</span>
        {showOptional && (
          <span className="italic capitalize">{`(${t(
            "common:optional"
          )})`}</span>
        )}
      </p>
    </label>
  );
};

export default Label;
